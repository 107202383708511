.FormSubmitUiWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top:0;
    left: 0;
    z-index: 9999;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.FormSubmitUi{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
    padding: 5%;
    text-align: center;
    font-size: 1.2rem;
}

.FormSubmitUi h1{
    color:  green;
}

.FormSubmitUi img{
    width: 20%;
    animation: 3s flip infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}


.FormSubmitUi p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width:80%
}

.FormSubmitUi button{
    color: white;
    font-weight: bold;
    background:rgba(0, 128, 0, 0.8);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    padding: 15px;
    margin: 20px;
    /* width: 40%;
    height: 25%; */
}

.FormSubmitUi button:hover{
    transform: scale(1.1);
    background-color: green;
}

.FormSubmitUi button:active{
    transform: scale(0.9);
}


@media screen and (max-width:884px) {
    .FormSubmitUi{
        justify-content: center;
        gap: 30px;
    }
}

@media screen and (max-width:428px) {
    .FormSubmitUi img{
        width: 30%;
    }
}

@media screen and (max-width:360px) {
    .FormSubmitUi img{
        width: 30%;
    }
    .FormSubmitUi{
        justify-content: center;
        gap: 10px;
    }
    .FormSubmitUi p{
        font-size: 1rem;
    }
}