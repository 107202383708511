.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    /* text-align: cen; */
    font-family: Arial, Helvetica, sans-serif;
  }

  .footerdisappear{
    display: none;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .footer-links, .footer-contact, .footer-payment, .footer-social {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin: 10px;
    /* gap: 10px; */
  }

  .footer-links{
    gap:7px;
  }

  .footer-links h4{
    text-decoration: underline;
  }
  
  .footer-link, .social-link {
    color: white;
    text-decoration: none;
  }
  
  .footer-link:hover, .social-link:hover {
    text-decoration: underline;
  }
  
  .footer-contact p, .footer-payment p, .footer-payment ul, .footer-payment p {
    margin: 5px 0;
    align-items: center;
    justify-content: center;
  }

  .footer-contact p, .footer-links a{
    font-size: 0.9rem;
  }
  
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
    }
  }

  @media (max-width: 428px) {
    .footer-social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
    .footer-container{
      gap:50px;
    }
  }
  