/* DeliveryDetailPage.css */
/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  
  .delivery-form-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 150px;
    padding-bottom: 100px;
    flex-direction: column;
    gap:20px;
    width: 100%;
    align-items: center;
    min-height: 100vh;
  }

  .delivery-form-container h1{
    color: green;
    text-align: center;
  }
  
  .delivery-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    gap: 10px;
  }
  
  .delivery-form label {
    display: flex;
    flex-direction: column;
  }

  .delivery-form label{
    color: green;
  }
  
  .delivery-form input,
  .delivery-form select {
    padding: 10px;
    font-size: 16px;
    margin: 5px 0;
    width: 100%;
    outline:none;
  }
  
  @media (max-width: 600px) {
    .delivery-form {
      padding: 10px;
    }
  
    .delivery-form input,
    .delivery-form select {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    .delivery-form-container{
        padding-top: 50px;
    }
    .delivery-form-container h1{
        font-size: 1.5rem;
    }
  }
  