.VerifyContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column;
    gap: 30px;
    color: green;
  }
  
  .VerifyMessage {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .VerifyContainer {
      /* max-width: 90%; */
    }
  }
  