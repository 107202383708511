.cart {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 150px;
    font-family: Arial, Helvetica, sans-serif;
    /* height: 500px; */
    min-height: 100vh;
    /* overflow-y: hidden; */
  }
  
  .cart h2 {
    text-align: center;
    margin: 20px;
    color: green;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .cart-item img{
    width: 50px;
    height: 50px;
  }
  
  .item-name {
    flex: 2;
    margin: 5px 0;
    text-align: center;
  }
  
  .item-price {
    flex: 1;
    margin: 5px 0;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-controls button {
    background-color: rgba(0, 128, 0, 0.7);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .quantity-controls button:hover {
    background-color: rgba(0, 128, 0, 1);
  }
  
  .quantity-controls span {
    margin: 0 10px;
  }
  
  button.remove-button {
    background-color:rgba(0, 128, 0, 0.7);
    cursor: pointer;
    border: none;
    color: white;
    padding: 5px 10px;
  }
  
  button.remove-button:hover {
    background-color: #c9302c;
  }
  
  .cart-total {
    text-align: right;
    font-size: 1.2em;
    margin-top: 20px;
  }
  
  .checkout-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
  }
  
  .checkout-button:hover {
    background-color: #45a049;
  }
  
 .BackToDashLink{
    color: green;
    position: absolute;
    top: 150px;
    left:-150px;
 }
  
    .item-name, .item-price, .quantity-controls, .remove-button {
      margin: 0 10px;
    }
  
    .quantity-controls {
      flex: 1;
      justify-content: center;
    }
  
    .remove-button {
      flex: 1;
      max-width: 100px;
    }
  
    @media screen and (max-width:884px) {
      .BackToDashLink{
        left:-70px
      }
    }

    @media screen and (max-width:428px) {
        .cart{
            padding-top: 0px;
        }
        .cart-item{
            flex-direction: column;
            gap: 10px
            ;
        }
    }
  