.back-button-wrap {
    text-align: center;
    margin-top: 20px;
}

.back-button-wrap button {
    padding: 10px 20px;
    background-color: rgba(0, 128, 0, 0.8);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.back-button-wrap button:hover{
    background-color: green;
}
