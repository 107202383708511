.LoadingUiWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top:0;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999;

}

.LoadingUi{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    height: 200px;
    background-color:white;
    border-radius: 5px;
    gap: 20px;
}

.LoadingUi img{
    width: 150px;
    height: 150px;
    animation: flip 20s infinite;
}

.LoadingUi h2{
    color: green;
    text-align: center;
    animation: flip 4s infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.LoadingUi p{
    color: rgba(0, 128, 0, 1);
    font-weight: 600;
    font-style: italic;
}