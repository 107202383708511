.ProductDetailWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap:100px;
    flex-direction: column;
    padding: 150px 0px;
}

.product-detail {
    display: flex;
    flex-wrap: wrap;
    /* margin: 20px; */
    
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    font-family: Arial, Helvetica, sans-serif;
    
  }
  
  .image-gallery {
    width:40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    position: relative;
    gap: 10px;
  }
  
  .main-image {
    width: 70%;
    max-width: 600px;
    height: 350px;
    margin-bottom: 10px;
  }
  
  .thumbnail-images {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .thumbnail-image {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .thumbnail-imageActive{
    border: 2px solid green;
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .product-info {
    /* flex: 1 1 35%; */
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  
  .product-info h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: green;
  }
  
  .product-info span {
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .amount {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: green;
  }
  
  .buy-now, .add-to-cart {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .buy-now {
    background-color:rgba(0, 128, 0, 0.8);
    color: white;
    border: none;
    transition: 350ms;
    transform: scale(1);
  }

  .buy-now:hover{
    background-color: green;
  }
  
  .add-to-cart {
    background-color: none;
    color:green;
    font-weight: 600;
    border: 1px solid green;
  }
  
  .wishlist-icon {
    color:green;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top:0;
    right: 0;
  }

  .BackButtonWrap button{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;
    color: green;
    background-color:white;
    border: 1px solid green;
    cursor: pointer;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .product-detail {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  
    .image-gallery {
      margin-right: 0;
      margin-bottom: 20px;
      width: 90%;
    }

    .product-info{
      width: 90%;
    }
  }
  
  @media screen and (max-width:320px) {
    .thumbnail-image, .thumbnail-imageActive{
      width: 60px;
      height: 60px;
    }
    .thumbnail-images{
      padding: 0px 50px;
    }
  }