.wishlist {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    /* padding-top: 150px; */
    font-family: Arial, Helvetica, sans-serif;
    /* min-height: 100vh; */
  }
  
 .wishlist h2 {
    text-align: center;
    margin: 20px;
    color: green;
  }
  
  .wishlist-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .wishlist-item img{
    width: 50px;
    height: 50px;
  }
  
  .item-name {
    flex: 2;
    margin: 5px 0;
  }
  
  .item-price {
    flex: 1;
    margin: 5px 0;
  }
  
  button.remove-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  button.remove-button:hover {
    background-color: #c9302c;
  }
  
  @media (min-width: 600px) {
    .wishlist-item {
      flex-direction: row;
    }
  
    .item-name, .item-price, .remove-button {
      margin: 0 10px;
    
    }
  
    .remove-button {
      flex: 1;
      max-width: 100px;
    }
  }
  
  @media screen and (max-width:428px) {
   .wishlist{
    padding-top: 0px;
   } 
   .remove-button{
    margin-top: 10px;
   }
  }