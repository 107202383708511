.orders-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.orders-container h2 {
    text-align: center;
    color:green;
    margin-bottom: 20px;
  }
  
  .order {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 10px;
  }
  
  .order-header span {
    margin: 5px 0;
  }
  
  .order-details {
    padding: 10px 0;
  }
  
  .order-details > div {
    margin-bottom: 10px;
  }
  
  .cart-items ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .cart-items ul li {
    margin-bottom: 5px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .orders-container {
      padding: 15px;
    }
  
    .order-header, .order-details {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order-header span, .order-details > div {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  @media (max-width: 480px) {
    .orders-container {
      padding: 10px;
    }
  
    .orders-container h2 {
      font-size: 1.5em;
    }
  
    .order {
      padding: 10px;
    }
  
    .order-header span, .order-details > div {
      font-size: 0.9em;
    }
  }
  