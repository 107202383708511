.HeroWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    font-family: Arial, Helvetica, sans-serif;
    /* background-color: rgba(172, 255, 47, 0.425); */
    background-color: rgb(173, 252, 173);
    /* background-color: rgba(0, 128, 0, 0.1); */
    /* padding-top: 200px; */
    margin-top: 110px;
    position: relative;
}

.HeroLeft{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100%;
    /* border-radius: 10px; */
    /* background-color: red; */
}

.HeroRight{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    height: 100%;
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: column;
}

.HeroRight img{
    width: 90%;
    height: 45%;
    border-radius: 10px;
}
.Hero1, .Hero2, .Hero3{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 95%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.3rem;
    text-align: center;

}

.HeroLeft p, .HeroLeft h2{
    font-style: italic;
    padding: 20px;
    background-color: rgba(0, 128, 0, 0.486);
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    margin-top: 80px;
}

.Hero1{
    background-image: url("../Images/heroimg1.jpeg");
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.Hero2{
    background-image: url("../Images/heroimg2.jpeg");
    background-position: center;
    border-radius: 10px;
}

.Hero3{
    background-image: url("../Images/heroimg3.jpeg");
    background-position: center;
    border-radius: 10px;
}

.CircleWrap{
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    gap: 30px;
    position: absolute;
    bottom: 10px;
}

.Circle{
    width: 10px;
    height: 10px;
    background-color: grey;
    background-color: green;
    border-radius: 50px;
}

.CircleActive{
    width: 17px;
    height: 17px;
    background-color: rgba(0, 0, 255, 0.5);
    background-color: rgba(172, 255, 47, 0.8);
    border-radius: 50px;
}

@media screen and (max-width:884px) {
    .HeroWrap{
        /* padding-top: 20px; */
        height: 350px;
        margin-top: 0px;
    }
}



@media screen and (max-width:428px) {
    .HeroRight{
        display: none;

    }
    .HeroLeft{
        width: 95%;
        
    }
    .HeroWrap{
        /* margin-top: 120px; */
        margin-top: 0px;
        padding-top: 3px;
    }
}